import {
  Collapse,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { OrganizationApi } from '../../../api'
import { alertTypes } from '../../../util/constants'
import './styles.css'
import { writeToBuffer } from 'fast-csv'
import { saveAs } from 'file-saver'

const OrganizationReport = (props) => {
  const { organization, setAlertModal } = props

  const [state, setState] = useState({
    data: null,
    dateRanges: [],
    selectedDateRange: null,
    isLoading: false,
    dateType: 'default',
    startDate: moment(organization.created_at).format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    isGenerating: false,
  })

  useEffect(() => {
    fetchSubscriptionPeriods(organization.id)
  }, [organization.id])

  // get array of previous subscription periods
  const fetchSubscriptionPeriods = async (id) => {
    const result = await OrganizationApi.getSubscriptionPeriods(id)

    if (result && result.success) {
      let dates = result.data.map((x) => moment(x).format('YYYY-MM-DD HH:mm:ss'))
      let dateRanges = []
      for (var i = 0; i < dates.length; i++) {
        if (i < dates.length - 1) {
          if (i === 0) {
            dateRanges.push([dates[i], dates[i + 1]])
          } else {
            dateRanges.push([dates[i], dates[i + 1]])
          }
        }
      }

      // sets the subscription periods to flood dropdown data for users to select subscription period
      setState((prevState) => {
        return {
          ...prevState,
          dateRanges,
          isLoading: false,
        }
      })
    }
  }

  const onTypeChange = (event, value) => {
    event.persist()
    // if date type has changed, set the new date type
    if (value) {
      if (state.dateType !== value) {
        setState((prevState) => {
          return { ...prevState, dateType: value, data: null }
        })
      }
    }
  }

  const onDateRangeChange = (event) => {
    event.persist()
    setState((prevState) => {
      return { ...prevState, selectedDateRange: event.target.value }
    })
  }

  const onDateChange = (event) => {
    event.persist()

    setState((prevState) => {
      return { ...prevState, [event.target.id]: event.target.value }
    })
  }

  const onDateSubmit = async () => {
    setState((prevState) => {
      return { ...prevState, isLoading: true }
    })

    if (state.dateType === 'default') {
      if (!state.selectedDateRange) {
        setAlertModal({
          message: 'Please select a date range.',
          type: alertTypes.warning,
        })
        setState((prevState) => {
          return { ...prevState, isLoading: false }
        })
        return
      }
    }

    let startDate =
      state.dateType === 'default'
        ? state.selectedDateRange[0]
        : state.startDate
    let endDate =
      state.dateType === 'default' ? state.selectedDateRange[1] : state.endDate

    // with user selected dates, get statistics to display to report dashboard
    const result = await OrganizationApi.getStats(
      organization.id,
      startDate,
      endDate
    )
    if (result && result.success) {
      setState((prevState) => {
        return { ...prevState, data: result.data, isLoading: false }
      })
    }
    if (!result.success) {
      setAlertModal({
        message: result.error,
        type: alertTypes.error,
      })
      setState((prevState) => {
        return { ...prevState, isLoading: false }
      })
    }
  }

  const onExportClick = async () => {
    setState((prevState) => {
      return { ...prevState, isGenerating: true }
    })

    if (state.dateType === 'default') {
      if (!state.selectedDateRange) {
        setAlertModal({
          message: 'Please select a date range.',
          type: alertTypes.warning,
        })
        setState((prevState) => {
          return { ...prevState, isGenerating: false, data: null }
        })
        return
      }
    }

    let startDate =
      state.dateType === 'default'
        ? state.selectedDateRange[0]
        : state.startDate
    let endDate =
      state.dateType === 'default' ? state.selectedDateRange[1] : state.endDate

    // with user selected dates, get detailed report to generate report files
    const result = await OrganizationApi.generateReport(
      organization.id,
      startDate,
      endDate
    )

    // with detailed data, create individual csv file for each category
    if (result && result.success) {
      // once export has been initiated, write all data informations to a buffer
      let createCsv = new Promise((resolve) => {
        const data = []
        result.data.forEach((file) => {
          data.push({
            Name: file.fileName,
            Size: bytesFormat(file.fileSize),
            Product: file.productName,
            project: file.projectName,
            Uploader: file.uploaderName,
            Email: file.uploaderEmail,
            CreatedAt: file.fileCreatedAt,
            Deleted: file.fileDeletedAt ? 'Y' : 'N',
            'External ID': file.externalId
          })
        })
        writeToBuffer(data, { headers: true }).then((buf) => {
          resolve(buf)
        })
      })

      // create csv file as blob for user to download
      createCsv
        .then((data) => {
          var blob = new Blob([data.toString()], {
            type: 'text/plain;charset=utf-8',
          })

          let dateRange =
            state.dataType === 'default'
              ? `${state.selectedDateRange[0]
                .split('-')
                .join('')}-${state.selectedDateRange[1].split('-').join('')}`
              : `${state.startDate.split('-').join('')}-${state.endDate
                .split('-')
                .join('')}`
          saveAs(
            blob,
            `${organization.name.split(' ').join('_')}-${dateRange}.csv`
          )
        })
        .finally(() => {
          setState((prevState) => {
            return { ...prevState, isGenerating: false, data: null }
          })
        })
    }

    if (!result.success) {
      setAlertModal({
        message: result.error,
        type: alertTypes.error,
      })
      setState((prevState) => {
        return { ...prevState, isGenerating: false, data: null }
      })
    }
  }

  const bytesFormat = (x) => {
    const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    let l = 0,
      n = parseInt(x, 10) || 0
    while (n >= 1024 && ++l) {
      n = n / 1024
    }
    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]
  }

  return (
    <div id="organization-stats">
      <div className="row">
        <div className="col" style={{ marginBottom: '1rem' }}>
          <ToggleButtonGroup
            color="primary"
            exclusive
            value={state.dateType}
            onChange={onTypeChange}
            style={{ height: '2rem' }}
          >
            <ToggleButton value="default">Date Range</ToggleButton>
            <ToggleButton value="custom">Custom Date</ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>

      <div className="row" style={{ marginBottom: '2rem' }}>
        <div className="col-3">
          {state.dateType === 'default' && (
            <>
              <FormControl style={{ width: '100%' }}>
                <InputLabel>Select Period</InputLabel>
                <Select
                  labelId="date_range_label"
                  id="date_range"
                  name="date_range"
                  label="dateRange"
                  value={state.selectedDateRange ? state.selectedDateRange : ''}
                  onChange={onDateRangeChange}
                >
                  <MenuItem disabled key="" value="">
                    <em>Select Period</em>
                  </MenuItem>
                  {state.dateRanges.map((item) => {
                    return (
                      <MenuItem key={item} value={item}>
                        {item[0].split(' ')[0]} to {item[1].split(' ')[0]}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </>
          )}

          {state.dateType === 'custom' && (
            <>
              <TextField
                id="startDate"
                label="Start Date"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={state.startDate}
                onChange={onDateChange}
                style={{ marginRight: '2rem' }}
              />
              <TextField
                id="endDate"
                label="End Date"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={state.endDate}
                onChange={onDateChange}
              />
            </>
          )}
        </div>

        <div className="col" style={{ height: '3rem', marginInline: '3rem'}}>
          <button
            type="button"
            className="btn-primary btn-primary-white"
            style={{
              width: '20%',
              height: '100%',
              marginRight: '1rem',
              padding: '0px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
            onClick={onDateSubmit}
          >
            {state.isLoading ? (
              <>
                <i className="fa fa-circle-o-notch fa-spin"></i>
              </>
            ) : (
              'Search'
            )}
          </button>
          <button
            type="button"
            className="btn-primary btn-primary-white"
            style={{
              width: '20%',
              height: '100%',
              padding: '0px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
            onClick={onExportClick}
          >
            {state.isGenerating ? (
              <>
                <i className="fa fa-circle-o-notch fa-spin"></i>
              </>
            ) : (
              'Export CSV'
            )}
          </button>
        </div>
      </div>

      <Collapse
        in={
          state.data && !state.isLoading && !state.isGenerating ? true : false
        }
      >
        <div className="row" style={{ height: '10rem' }}>
          <div className="organization-stats__content">
            <div
              className="organization-stat"
              style={{
                borderRight: '1px solid rgba(145, 145, 145, .2)',
                paddingRight: '4rem',
              }}
            >
              <h2>Projects</h2>
              <h1>
                {state.data && state.data.projects ? state.data.projects : '0'}
              </h1>
            </div>

            <div
              className="organization-stat"
              style={{
                borderRight: '1px solid rgba(145, 145, 145, .2)',
                paddingRight: '4rem',
              }}
            >
              <h2>Products</h2>
              <h1>
                {state.data && state.data.products ? state.data.products : '0'}
              </h1>
            </div>

            <div
              className="organization-stat"
              style={{
                borderRight: '1px solid rgba(145, 145, 145, .2)',
                paddingRight: '4rem',
              }}
            >
              <h2>Files</h2>
              <h1>{state.data && state.data.files ? state.data.files : '0'}</h1>
            </div>

            <div
              className="organization-stat"
              style={{
                borderRight: '1px solid rgba(145, 145, 145, .2)',
                paddingRight: '4rem',
              }}
            >
              <h2>Uploaded</h2>
              <h1>
                {state.data && state.data.fileSize
                  ? bytesFormat(state.data.fileSize)
                  : '0'}
              </h1>
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  )
}

export default OrganizationReport
